import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import ChangeMemberUnitSelectUnit from "./ChangeMemberUnitSelectUnit";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { encryptStorage } from "../../../constants/EncryptStorage";
import swal from "sweetalert";
import { addMember } from "../ApiMember";
import { toast } from "react-toastify";
import { GetSelectedLanguage } from "../../helper/Method";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import Draggable from "react-draggable";
import moment from "moment";

const ChangeMemberUnit = ({
  setChangeUnitModal,
  memberId,
  previousUnitId,
  memberUnitData,
  addNewRafiq,
  setUpdatedDetails,
  setUnitStateChange,
  unitDates,
  edit = false,
}) => {
  const notify = (string) => toast.success(string);
  const toggle = () => setChangeUnitModal(false);
  const { t } = useTranslation();
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [nextDate, setNextDate] = useState();
  const [previousDate, setPreviousDate] = useState();
  const [selectedDate, setSelectedDate] = useState(
    memberUnitData?.unitDate ? new Date(memberUnitData?.unitDate) : new Date()
  );

  const [memberUnit, setMemberUnit] = useState(
    memberUnitData !== null
      ? {
          memberUnitId: memberUnitData.id,
          id: previousUnitId,
          memberId: memberId,
          unit: { value: memberUnitData.unitId, label: "" },
          date: convertToDateString(selectedDate),
          description: memberUnitData.description,
          referenceNote: memberUnitData.referenceNote,
        }
      : {
          id: previousUnitId,
          memberId: memberId,
          unit: { value: loggedInUnitId, label: "" },
          date: new Date(),
          description: "",
          referenceNote: "",
        }
  );
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const index = unitDates.findIndex(
      (date) => date === memberUnitData?.unitDate
    );

    setPreviousDate(unitDates[index + 1] || null);
    setNextDate(unitDates[index - 1] || null);
  }, [unitDates, memberUnitData]);

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberUnit({
      ...memberUnit,
      [name]: value,
    });
  };

  const handleInputChangeDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDate(value);
        setErrors({
          ...errors,
          editDate: "",
        });
      }
    }
  };

  const validate = () => {
    let temp = {};
    temp.editDate =
      (previousDate && convertToDateString(selectedDate) <= previousDate) ||
      (nextDate && convertToDateString(selectedDate) >= nextDate)
        ? "error"
        : "";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      memberUnit.date = convertToDateString(selectedDate);
      setButtonDisable(true);
      setLoading(true);
      addMember()
        .addMemberUnit(memberUnit, addNewRafiq)
        .then((res) => {
          if (res.data === "") {
            swal({
              title: t(
                edit
                  ? "member__member_unit_update_success_message"
                  : "member__member_unit_added_success_message"
              ),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setUpdatedDetails((prevState) => !prevState);
            setUnitStateChange((prevState) => !prevState);
          } else notify(res.data);
          setLoading(false);
          setButtonDisable(false);
          toggle();
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: "Error: " + err.response?.data,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
          toggle();
        });
    }
  };
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size="lg"
          isOpen={true}
          className="inmodal"
          autoFocus={false}
          style={{ maxWidth: "1080px", width: "100%" }}
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_member_detail_chnage_unit" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              {!loading ? (
                <div className="ibox-content">
                  <ChangeMemberUnitSelectUnit
                    values={memberUnit}
                    setValues={setMemberUnit}
                    unitId={memberUnit.unit.value}
                  />
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_joining_date" />}
                        </Label>
                        <DatePicker
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mm/yyyy"
                          className="form-control no-drag"
                          selected={selectedDate}
                          maxDate={new Date()}
                          onChange={handleInputChangeDate}
                          dateFormat="dd/MM/yyyy"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="courses_description" />}
                        </Label>
                        <Input
                          className="ur no-drag"
                          type="text"
                          value={memberUnit.description}
                          name="description"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_reference_note" />}
                        </Label>
                        <Input
                          className="ur no-drag"
                          type="text"
                          value={memberUnit.referenceNote}
                          name="referenceNote"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {nextDate || previousDate ? (
                      <h5
                        className={
                          errors.editDate && errors.editDate === "error"
                            ? "text-error"
                            : ""
                        }
                      >
                        *
                        {GetSelectedLanguage() === "ur" ? (
                          <h5 className={"ur"}>
                            <span>یونٹ کی تاریخ </span>
                            {nextDate
                              ? `${moment(nextDate).format(
                                  "DD/MM/yyyy"
                                )} سے کم` +
                                (previousDate
                                  ? ` اور ${moment(previousDate).format(
                                      "DD/MM/yyyy"
                                    )} سے زیادہ`
                                  : "")
                              : `${moment(previousDate).format(
                                  "DD/MM/yyyy"
                                )} سے زیادہ `}{" "}
                            <span>ہونی چاہیے </span>
                          </h5>
                        ) : (
                          <>
                            <span>Unit date must be </span>
                            {nextDate
                              ? `less than ${moment(nextDate).format(
                                  "DD/MM/yyyy"
                                )}` +
                                (previousDate
                                  ? ` and greater than ${moment(
                                      previousDate
                                    ).format("DD/MM/yyyy")}`
                                  : "")
                              : `greater than ${moment(previousDate).format(
                                  "DD/MM/yyyy"
                                )}`}
                          </>
                        )}
                      </h5>
                    ) : null}
                  </Row>
                </div>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button disabled={buttonDisable} color="primary" type="submit">
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default ChangeMemberUnit;
