import React, { useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import {
  LoadingSpinner,
  isUrdu,
  removeDuplicates,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiFolders } from "./ApiDocuments";
import Permissions from "./PermissionsComponent";

const AddNewFolder = ({
  setNewFolderModal,
  parentFolderId,
  uploadRefreshDocuments,
  documents,
  setDocuments,
}) => {
  const toggle = () => {
    setNewFolderModal(false);
  };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [folderPermissions, setFolderPermissions] = useState(0);
  const [inheritFolderPermissions, setInheritFolderPermissions] = useState(2);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const memberName = encryptStorage.getItem("memberName");
  const unitName = encryptStorage.getItem("loggedInUnitName");
  const responsibiityName = encryptStorage.getItem("responsibilityName");
  const [values, setValues] = useState({
    folderName: "",
    description: "",
    parentFolderId: parentFolderId,
    permissionsApplied: folderPermissions === 1,
    inheritPermissionsFromParent: inheritFolderPermissions === 2,
    permissions: [],
    permissionFolderId: 0
  });

  if (parentFolderId === 0) parentFolderId = 1;

  const [permissions, setPermissions] = useState([
    {
      unit: { value: loggedInUnitId, label: "" },
      forChildUnits: false,
      responsibilities: [],
      selectUnit: true,
      selectResponsibilities: false,
      selectAllResponsibilities: false,
    },
  ]);

  const addPermission = () => {
    const tempValues = [
      {
        unit: { value: 0, label: "" },
        forChildUnits: false,
        responsibilities: [],
        selectUnit: true,
        selectResponsibilities: false,
        selectAllResponsibilities: false,
      },
      ...permissions,
    ];
    setPermissions(tempValues);
  };

  const removeObject = (id) => {
    let tempValues = [...permissions];
    tempValues.splice(id, 1);
    setPermissions(tempValues);
  };
  
  const handleFolderPermissions = (e) => {
    const { value } = e.target;
    setFolderPermissions(parseInt(value));
    setInheritFolderPermissions(0);
    setValues({
      ...values,
      permissionsApplied: value === 1,
      inheritPermissionsFromParent: false,
    });
  };
  const handleInheritFolderPermissions = (e) => {
    const { value } = e.target;
    setInheritFolderPermissions(parseInt(value));
    setFolderPermissions(0);
    setValues({
      ...values,
      inheritPermissionsFromParent: value === 2,
      permissionsApplied: false,
    });
  };
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [errors, setErrors] = useState({ folderName: "" });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const validate = () => {
    let temp = {};
    temp.folderName = values.folderName
      ? ""
      : t("document_new_folder_name_error");
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (name === "folderName") setErrors("");
  };

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      values.parentFolderId = parentFolderId;
      values.permissionsApplied = folderPermissions === 1;
      values.inheritPermissionsFromParent = inheritFolderPermissions === 2;
      if (folderPermissions) {
        values.permissions = removeDuplicates(permissions);
      }

      const tempValues = [...documents];
      const date = new Date();
      tempValues.push({
        name: values.folderName,
        fileType: "folder",
        uploaderName: memberName,
        uploaderResponsibility: responsibiityName,
        uploaderUnit: unitName,
        description: values.description,
        size: 0,
        fileStatus: "file_in_progress",
        createdDate: date,
      });

      setDocuments(tempValues);

      ApiFolders()
        .addFolder(values)
        .then((res) => {
          swal({
            title: t("document_new_folder_created"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          toggle();
          uploadRefreshDocuments();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  };

  return (
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal
        size={folderPermissions ? "lg" : "m"}
        style={{
          maxWidth: folderPermissions ? "1080px" : "600px",
          width: "100%",
        }}
        isOpen
        className="inmodal"
        autoFocus={false}
      >
        <ModalHeader className="modal-title" tag="h4">
          {<GetLanguageString props="document_add_new_folder" />}
        </ModalHeader>
        {!loading ? (
          <Form>
            <ModalBody>
              <Form>
                <Row>
                  <Col md={folderPermissions ? "6" : "12"}>
                    <FormGroup>
                      <Label for="folderName">
                        <GetLanguageString props={"common_name"} />
                      </Label>
                      <Input
                        className={
                          isUrdu(values.folderName) ? "ur no-drag" : "no-drag"
                        }
                        type="text"
                        name="folderName"
                        value={values.folderName}
                        onChange={handleInputChange}
                        {...(errors?.folderName && { invalid: true })}
                      />
                      {errors?.folderName && (
                        <FormFeedback>
                          <h4>{errors?.folderName}</h4>
                        </FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={folderPermissions ? "6" : "12"}>
                    {" "}
                    <FormGroup>
                      <Label for="description">
                        <GetLanguageString props={"common_description"} />
                      </Label>
                      <Input
                        className={
                          isUrdu(values.description) ? "ur no-drag" : "no-drag"
                        }
                        type="text"
                        name="description"
                        value={values.description}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Label check for={"inheritFolderPermissions"}>
                      <Input
                        type="radio"
                        id="inheritFolderPermissions"
                        value={2}
                        name="inheritFolderPermissions"
                        checked={inheritFolderPermissions === 2}
                        onChange={handleInheritFolderPermissions}
                      />
                      &nbsp;&nbsp;
                      {
                        <GetLanguageString
                          props={"folder_document_inherit_permissions"}
                        />
                      }
                    </Label>
                  </Col>
                  <Col md="6">
                    <Label check for={"folderPermissions"}>
                      <Input
                        type="radio"
                        id="folderPermissions"
                        value={1}
                        name="folderPermissions"
                        checked={folderPermissions === 1}
                        onChange={handleFolderPermissions}
                      />
                      &nbsp;&nbsp;
                      {
                        <GetLanguageString
                          props={"folder_document_apply_permissions"}
                        />
                      }
                    </Label>
                  </Col>
                </Row>
                {folderPermissions === 1 && (
                  <>
                    <br></br>
                    <div className="ibox-content">
                      <Row>
                        <Col md="11"></Col>
                        <Col md="1" className="text-center">
                          <div>
                            <FaSquarePlus
                              color="green"
                              size={25}
                              style={{ cursor: "pointer" }}
                              onClick={addPermission}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {permissions?.map((item, index) => {
                          return (
                            <>
                              <div>
                                <br></br>
                                <Permissions
                                  item={item}
                                  index={index}
                                  permissions={permissions}
                                  setPermissions={setPermissions}
                                />
                                <br></br>
                              </div>
                              {permissions.length !== 1 && (
                                <Row>
                                  <Col md="11"></Col>
                                  <Col md="1" className="text-center">
                                    <div>
                                      <FaSquareMinus
                                        color="red"
                                        size={25}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => removeObject(index)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <div>
                                {index + 1 !== permissions.length && (
                                  <div class="divider div-transparent div-dot"></div>
                                )}
                                <br></br>
                              </div>
                            </>
                          );
                        })}
                      </Row>
                    </div>
                  </>
                )}
              </Form>
            </ModalBody>
          </Form>
        ) : (
          LoadingSpinner()
        )}
        <ModalFooter>
          <Button color="white" onClick={toggle}>
            {<GetLanguageString props="common_cancel" />}
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            {<GetLanguageString props="common_save_changes" />}
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default AddNewFolder;
