import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "reactstrap";
import { GetLanguageString, NoResult } from "../../helper/Components";
import moment from "moment";
import { convertUTCToLocalTime } from "../../../constants/const";
import { addMember } from "../ApiMember";
import swal from "sweetalert";

const MemberPledges = ({ tab7, pledges, editValue }) => {
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documents, setDocuments] = useState();

  const [viewMubtadiFrontButtonDisable, setViewMubtadiFrontButtonDisable] =
    useState(Array(documents?.length).fill(false));
  const [viewMubtadiBackButtonDisable, setViewMubtadiBackButtonDisable] =
    useState(Array(documents?.length).fill(false));
  const [viewMultazimFrontButtonDisable, setViewMultazimFrontButtonDisable] =
    useState(Array(documents?.length).fill(false));
  const [viewMultazimBackButtonDisable, setViewMultazimBackButtonDisable] =
    useState(Array(documents?.length).fill(false));

  useEffect(() => {
    if (tab7 === "active") {
      if (editValue !== 0) {
        setDocumentLoading(true);
        addMember()
          .GeMemberDocsNames(editValue)
          .then((res) => {
            setDocuments(res.data);
            setDocumentLoading(false);
          })
          .catch((err) => {
            swal({
              title: "Error: " + err.response?.data || err,
              icon: "error",
              buttons: "OK",
            });
            setDocumentLoading(false);
            console.log(err.response?.data);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab7, editValue]);

  const openFile = (docType, index, pledgeId) => {
    addMember()
      .GetMemberDocs(editValue, docType, pledgeId)
      .then((res) => {
        const binaryData = atob(res.data.bytes);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uintArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: res.data.fileType });
        const url = URL.createObjectURL(blob);

        const newTab = window.open();
        newTab.document.open();
        newTab.document.write(`
            <html>
            <head>
              <style>
                body {
                  margin: 0;
                  overflow: hidden;
                }
                iframe {
                  width: 100%;
                  height: 100%;
                  border: none;
                  background-color: transparent;
                }
              </style>
            </head>
            <body>
              <iframe src="${url}"></iframe>
            </body>
          </html>
        `);
        newTab.document.close();

        URL.revokeObjectURL(url);

        setViewMubtadiBackButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        setViewMubtadiFrontButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        setViewMultazimBackButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        setViewMultazimFrontButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
      })
      .catch((err) => {
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setViewMubtadiBackButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        setViewMubtadiFrontButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        setViewMultazimBackButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        setViewMultazimFrontButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        console.log(err.response.data);
      });
  };

  return (
    <div role="tabpanel" id="tab-7" className={"tab-pane " + tab7}>
      <div className="panel-body">
        {!documentLoading ? (
          pledges?.length !== 0 ? (
            <div className="table-responsive">
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="text-center">
                      {
                        <GetLanguageString props="member_member_detail_pledges_name" />
                      }
                    </th>
                    <th className="text-center">
                      {
                        <GetLanguageString props="member_member_detail_pledges_date" />
                      }
                    </th>
                    <th className="text-center">
                      {<GetLanguageString props="mubtadi_bait_form" />}
                    </th>
                    <th className="text-center">
                      {<GetLanguageString props="multazim_bait_form" />}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pledges?.map((record, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="ur text-center ">{record.pledgeName}</td>
                        <td className="text-center">
                          {record.pledgeDate
                            ? moment(
                                convertUTCToLocalTime(record.pledgeDate)
                              ).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        <td className="text-center">
                          {record.mubtadiBaitFormExistClass.front ||
                          record.mubtadiBaitFormExistClass.back ? (
                            <>
                              {record.mubtadiBaitFormExistClass.front ? (
                                <Button
                                  color="white"
                                  size="sm"
                                  disabled={
                                    viewMubtadiFrontButtonDisable[index]
                                  }
                                  onClick={() => {
                                    openFile(
                                      record.mubtadiBaitFormExistClass
                                        .frontFileType,
                                      index,
                                      record.pledgeId
                                    );
                                    setViewMubtadiFrontButtonDisable(
                                      (prevStates) => {
                                        const newLoadingStates = [
                                          ...prevStates,
                                        ];
                                        newLoadingStates[index] = true;
                                        return newLoadingStates;
                                      }
                                    );
                                  }}
                                >
                                  {viewMubtadiFrontButtonDisable[index] ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <>
                                      <i class="fa fa-eye"></i>{" "}
                                      <GetLanguageString props="document_front" />
                                    </>
                                  )}
                                </Button>
                              ) : null}{" "}
                              {record.mubtadiBaitFormExistClass.back ? (
                                <Button
                                  color="white"
                                  size="sm"
                                  disabled={viewMubtadiBackButtonDisable[index]}
                                  onClick={() => {
                                    openFile(
                                      record.mubtadiBaitFormExistClass
                                        .backFileType,
                                      index,
                                      record.pledgeId
                                    );
                                    setViewMubtadiBackButtonDisable(
                                      (prevStates) => {
                                        const newLoadingStates = [
                                          ...prevStates,
                                        ];
                                        newLoadingStates[index] = true;
                                        return newLoadingStates;
                                      }
                                    );
                                  }}
                                >
                                  {viewMubtadiBackButtonDisable[index] ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <>
                                      <i class="fa fa-eye"></i>{" "}
                                      <GetLanguageString props="document_back" />
                                    </>
                                  )}
                                </Button>
                              ) : null}
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="text-center">
                          {record.multazimBaitFormExistClass.front ||
                          record.multazimBaitFormExistClass.back ? (
                            <>
                              {record.multazimBaitFormExistClass.front ? (
                                <Button
                                  color="white"
                                  size="sm"
                                  disabled={
                                    viewMultazimFrontButtonDisable[index]
                                  }
                                  onClick={() => {
                                    openFile(
                                      record.mubtadiBaitFormExistClass
                                        .frontFileType,
                                      index
                                    );
                                    setViewMultazimFrontButtonDisable(
                                      (prevStates) => {
                                        const newLoadingStates = [
                                          ...prevStates,
                                        ];
                                        newLoadingStates[index] = true;
                                        return newLoadingStates;
                                      }
                                    );
                                  }}
                                >
                                  {viewMultazimFrontButtonDisable[index] ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <>
                                      <i class="fa fa-eye"></i>{" "}
                                      <GetLanguageString props="document_front" />
                                    </>
                                  )}
                                </Button>
                              ) : null}{" "}
                              {record.multazimBaitFormExistClass.back ? (
                                <Button
                                  color="white"
                                  size="sm"
                                  disabled={
                                    viewMultazimBackButtonDisable[index]
                                  }
                                  onClick={() => {
                                    openFile(
                                      record.mubtadiBaitFormExistClass
                                        .backFileType,
                                      index,
                                      record.pledgeId
                                    );
                                    setViewMultazimBackButtonDisable(
                                      (prevStates) => {
                                        const newLoadingStates = [
                                          ...prevStates,
                                        ];
                                        newLoadingStates[index] = true;
                                        return newLoadingStates;
                                      }
                                    );
                                  }}
                                >
                                  {viewMultazimBackButtonDisable[index] ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <>
                                      <i class="fa fa-eye"></i>{" "}
                                      <GetLanguageString props="document_back" />
                                    </>
                                  )}
                                </Button>
                              ) : null}
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : null
        ) : (
          <NoResult />
        )}
      </div>
    </div>
  );
};

export default MemberPledges;
