import React, { useCallback, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tooltip,
} from "reactstrap";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import { isUrdu, removeDuplicates } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString } from "../helper/Components";
import { ApiDocuments, ApiFolders } from "./ApiDocuments";
import Permissions from "./PermissionsComponent";

const ignoredFiles = [
  "thumbnail.db",
  "thumbs.db",
  "ehthumbs.db",
  "desktop.ini",
  ".ds_store",
  ".spotlight-v100",
  ".trashes",
  ".temporaryitems",
];

const UploadFolder = ({
  parentFolderId,
  uploadRefreshDocuments,
  uploadFolderModal,
  setUploadFolderModal,
  setUploadInProgress,
  uploadInProgress,
  droppedFolder,
  setDroppedFolder,
  documents,
  setDocuments,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [folderQueue, setFolderQueue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createdFolders, setCreatedFolders] = useState(new Set());
  const [remainingUploads, setRemainingUploads] = useState(-1);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const memberName = encryptStorage.getItem("memberName");
  const unitName = encryptStorage.getItem("loggedInUnitName");
  const responsibiityName = encryptStorage.getItem("responsibilityName");
  const [filePermissions, setFilePermissions] = useState(0);
  const [inheritFolderPermissions, setInheritFolderPermissions] = useState(2);
  const [description, setDescription] = useState("");
  const [permissionFolderId, setPermissionFolderId] = useState(0);
  const [modal, setModal] = useState(true);
  const [errors, setErrors] = useState();

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const [rootFolderId, setRootFolderId] = useState();

  const addPermission = () => {
    const tempValues = [
      {
        unit: { value: 0, label: "" },
        forChildUnits: false,
        responsibilities: [],
        selectUnit: true,
        selectResponsibilities: false,
        selectAllResponsibilities: false,
      },
      ...permissions,
    ];
    setPermissions(tempValues);
  };

  const removeObject = (id) => {
    let tempValues = [...permissions];
    tempValues.splice(id, 1);
    setPermissions(tempValues);
  };
  const handleFilePermissions = (e) => {
    const { value } = e.target;
    setFilePermissions(parseInt(value));
    setInheritFolderPermissions(0);
  };

  const handleInheritFolderPermissions = (e) => {
    const { value } = e.target;
    setInheritFolderPermissions(parseInt(value));
    setFilePermissions(0);
  };

  if (parentFolderId === 0) parentFolderId = 1;

  const [permissions, setPermissions] = useState([
    {
      unit: { value: loggedInUnitId, label: "" },
      forChildUnits: false,
      responsibilities: [],
      selectUnit: true,
      selectResponsibilities: false,
      selectAllResponsibilities: false,
    },
  ]);

  const toggleModal = () => {
    setModal(false);
    setDroppedFolder(null);
  };

  const validate = () => {
    let temp = {};

    const filesErrors = files.map((file, index) => {
      const fileType = file?.type || file?.name.split(".").pop().toLowerCase();

      // Check conditions for each file
      if (!file) {
        return <GetLanguageString props="file_error" />;
      } else if (file.size > 26214400) {
        return <GetLanguageString props="file_size_limit_exceed_error" />;
      } else if (
        fileType === "exe" ||
        fileType === "msi" ||
        fileType === "application/x-msdownload"
      ) {
        return <GetLanguageString props="file_type_error" />;
      } else {
        return ""; // No error
      }
    });
    temp.uploadedFiles = filesErrors.some((error) => error !== "")
      ? filesErrors
      : "";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const validateFiles = (files, setErrors, setFiles, errors, t) => {
    const newFiles = [];
    const fileErrors = [];

    files.forEach((file) => {
      if (ignoredFiles.includes(file.name.toLowerCase())) {
        return; // Skip this file and continue with the next one
      }
      // Size check (25 MB in bytes)
      if (file.size > 26214400) {
        fileErrors.push(
          t("file_size_limit_exceed_error") +
            " ( " +
            (file.relativePath ||
              file.path?.slice(1) ||
              file.webkitRelativePath) +
            " ) "
        );
      }

      // File type check (no executable files)
      const fileType = file.type || file.name.split(".").pop().toLowerCase();
      if (
        fileType === "exe" ||
        fileType === "msi" ||
        fileType === "application/x-msdownload" ||
        fileType === "application/octet-stream"
      ) {
        fileErrors.push(
          t("file_type_error") +
            "( " +
            (file.relativePath ||
              file.path?.slice(1) ||
              file.webkitRelativePath) +
            " ) "
        );
      }

      // Add valid files to the newFiles array
      if (
        file.size <= 26214400 &&
        ![
          "exe",
          "msi",
          "application/x-msdownload",
          "application/octet-stream",
        ].includes(fileType)
      ) {
        newFiles.push(file);
      }
    });

    // Set error messages if any, otherwise clear errors
    setErrors({
      ...errors,
      uploadedFiles: fileErrors.length
        ? `${fileErrors.join("<br />")}<br /><br />${t(
            "document_files_not_uploaded_error"
          )}`
        : "",
    });

    // Structure the valid files
    const structuredFiles = newFiles.map((file) => ({
      name: file.name,
      relativePath:
        file.relativePath || file.path?.slice(1) || file.webkitRelativePath,
      size: file.size,
      fileObj: file,
    }));

    // Update the state with structured files
    setFiles(structuredFiles);
  };

  useEffect(() => {
    if (droppedFolder !== null && droppedFolder?.length > 0) {
      validateFiles(droppedFolder, setErrors, setFiles, errors, t);
    }
    // eslint-disable-next-line
  }, [droppedFolder]);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    validateFiles(selectedFiles, setErrors, setFiles, errors, t);
  };

  const toastId = useRef(0); // Persist toastId across renders
  const processFolderStructure = () => {
    if (validate()) {
      if (!files.length) return;
      const tempRootFolderId = uuidv4();
      const rootFolderName = files[0].relativePath.split("/")[0];
      const rootFolder = {
        name: rootFolderName,
        parentFolderId: parentFolderId,
        subFolders: [],
        files: [],
      };

      const tempValues = [...documents];
      const date = new Date();
      tempValues.push({
        id: tempRootFolderId,
        name: rootFolderName,
        fileType: "folder",
        uploaderName: memberName,
        uploaderResponsibility: responsibiityName,
        uploaderUnit: unitName,
        description: description,
        size: 0,
        fileStatus: "file_in_progress",
        createdDate: date,
      });

      setRootFolderId(tempRootFolderId);
      setDocuments(tempValues);

      files.forEach((file) => {
        const pathParts = file.relativePath.split("/");
        let currentFolder = rootFolder;
        pathParts.slice(1).forEach((part, index) => {
          if (index === pathParts.length - 2) {
            currentFolder.files.push(file);
          } else {
            let existingFolder = currentFolder.subFolders.find(
              (f) => f.name === part
            );
            if (!existingFolder) {
              existingFolder = {
                name: part,
                subFolders: [],
                files: [],
                parentFolderId: null,
              };
              currentFolder.subFolders.push(existingFolder);
            }
            currentFolder = existingFolder;
          }
        });
      });

      setFolderQueue([rootFolder]);

      // Use toastId.current to keep the value across renders
      toastId.current = toast.loading(t("document_upload_folder_toast"), {
        position: toast.POSITION.BOTTOM_LEFT,
        style: {
          backgroundColor: "#40576d",
          color: "#DFE4ED",
        },
      });

      // Set the remaining uploads count for all files and folders to be uploaded
      const totalItems = files.length + countFolders(rootFolder);
      setRemainingUploads(totalItems);
    }
  };

  // Utility function to count all nested folders
  const countFolders = (folder) => {
    return folder.subFolders.reduce(
      (count, subFolder) => count + 1 + countFolders(subFolder),
      0
    );
  };

  const uploadNextFolder = useCallback(async () => {
    if (folderQueue.length === 0) return;

    setLoading(true);
    const currentFolder = folderQueue[0];
    try {
      setUploadInProgress(true);
      toggleModal();
      const folderIdentifier = `${currentFolder.parentFolderId}/${currentFolder.name}`;

      if (!createdFolders.has(folderIdentifier)) {
        const folderResponse = await ApiFolders().addFolder({
          folderName: currentFolder.name,
          description: description,
          parentFolderId: currentFolder.parentFolderId,
          permissionsApplied: filePermissions === 1,
          inheritPermissionsFromParent: inheritFolderPermissions === 2,
          permissions: removeDuplicates(permissions),
          permissionFolderId: permissionFolderId,
        });

        if (!folderResponse.data || !folderResponse.data.folderId) {
          throw new Error("Folder creation failed: No folder ID returned.");
        }
        const folderId = folderResponse.data.folderId;

        setDocuments((prevDocuments) =>
          prevDocuments.map((doc) =>
            doc.id === rootFolderId
              ? { ...doc, id: folderId, fileStatus: "folder_exists" }
              : doc
          )
        );

        if (permissionFolderId === 0) {
          setPermissionFolderId(folderId);
        }

        setCreatedFolders((prev) => new Set(prev).add(folderIdentifier));

        for (const file of currentFolder.files) {
          setUploadInProgress(true);
          const formData = new FormData();
          formData.append("FileType", 0);
          formData.append("Description", "^");
          formData.append("FileName", "^");
          formData.append("FileLink", "^");
          formData.append("IsLink", false);
          formData.append("Index", 0);
          formData.append("ParentFolderId", folderId);
          formData.append("PermissionFolderId", permissionFolderId === 0 ? folderId : permissionFolderId);
          formData.append("PermissionsApplied", filePermissions === 1);
          formData.append(
            "InheritPermissionsFromParentFolder",
            inheritFolderPermissions === 2
          );
          formData.append(
            "Permissions",
            JSON.stringify(removeDuplicates(permissions))
          );
          formData.append("tags", JSON.stringify([]));
          formData.append("file", file.fileObj);

          await ApiDocuments().addDocument(formData);
          setRemainingUploads((prev) => prev - 1); // Decrement remaining uploads after each file upload
        }

        const updatedQueue = folderQueue.slice(1);
        currentFolder.subFolders.forEach((subFolder) => {
          subFolder.parentFolderId = folderId;
          updatedQueue.push(subFolder);
        });
        setFolderQueue(updatedQueue);
        setRemainingUploads((prev) => prev - 1); // Decrement remaining uploads for the folder
      } else {
        setFolderQueue(folderQueue.slice(1));
      }
    } catch (err) {
      swal({
        title: err.message || "Error occurred during folder upload",
        icon: "error",
        buttons: "OK",
      });
      toast.update(toastId.current, {
        render: "Error occurred",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        style: {
          backgroundColor: "#ffffff",
          color: "grey",
        },
      });
      setUploadInProgress(false);
      toggleModal();
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [folderQueue, createdFolders]);

  useEffect(() => {
    if (folderQueue.length > 0 && !loading) {
      uploadNextFolder();
    }
  }, [folderQueue, loading, uploadNextFolder]);

  useEffect(() => {
    if (remainingUploads === 0) {
      toast.update(toastId.current, {
        render: t("document_upload_folder_toast_success"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
        style: {
          backgroundColor: "#ffffff",
          color: "grey",
        },
      });
      uploadRefreshDocuments();
      setUploadInProgress(false);
      setUploadFolderModal(!uploadFolderModal);
      toggleModal();
    }
    // eslint-disable-next-line
  }, [remainingUploads, uploadInProgress]);

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size={filePermissions ? "lg" : "m"}
          style={{
            maxWidth: filePermissions ? "980px" : "600px",
            width: "100%",
          }}
          isOpen={modal}
          className="inmodal"
          autoFocus={false}
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="document_upload_file" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              <Row>
                <Col md={filePermissions ? "6" : "12"}>
                  <FormGroup>
                    <Label for="folderUpload">
                      <GetLanguageString
                        props={
                          droppedFolder !== null
                            ? "document_upload_dropped_folder"
                            : "document_upload_folder"
                        }
                      />
                    </Label>
                    {droppedFolder !== null ? (
                      <>
                        <Input
                          type="text"
                          value={files.length + " Files"}
                          id="filesTooltip"
                          readOnly
                        />
                        <Tooltip
                          isOpen={tooltipOpen}
                          target="filesTooltip"
                          toggle={toggleTooltip}
                          placement="right"
                          delay={{ show: 0, hide: 200 }} // Optional delay
                          autohide={false} // Disable auto-hide
                        >
                          <div
                            onMouseEnter={() => setTooltipOpen(true)} // Keep tooltip open when hovered
                            onMouseLeave={() => setTooltipOpen(false)} // Close tooltip when not hovered
                            style={{
                              maxHeight: "600px", // Limit height
                              overflowY: files.length > 30 ? "auto" : "none", // Enable scrolling if content overflows
                              margin: 0,
                              padding: 0,
                              listStyleType: "none",
                            }}
                          >
                            <ul
                              style={{
                                margin: 0,
                                padding: 0,
                                listStyleType: "none",
                              }}
                            >
                              {files.map((file, index) => (
                                <li
                                  key={index}
                                  style={{
                                    fontSize: "12px",
                                    marginBottom: "5px", // Space between items
                                  }}
                                >
                                  {file.relativePath}
                                  <hr style={{ margin: "5px 0" }} />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Tooltip>
                      </>
                    ) : (
                      <Input
                        id="folderUpload"
                        type="file"
                        webkitdirectory="true"
                        multiple
                        onChange={handleFileChange}
                      />
                    )}
                    {errors?.uploadedFiles && (
                      <div
                        className="text-error"
                        dangerouslySetInnerHTML={{
                          __html: errors.uploadedFiles,
                        }}
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col md={filePermissions ? "6" : "12"}>
                  {" "}
                  <FormGroup>
                    <Label for="description">
                      <GetLanguageString props={"common_description"} />
                    </Label>
                    <Input
                      className={isUrdu(description) ? "ur no-drag" : "no-drag"}
                      type="text"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>{" "}
              <Row>
                <Col md="6">
                  <Label check for={"inheritFolderPermissions"}>
                    <Input
                      type="radio"
                      id="inheritFolderPermissions"
                      value={2}
                      name="inheritFolderPermissions"
                      checked={inheritFolderPermissions === 2}
                      onChange={handleInheritFolderPermissions}
                    />
                    &nbsp;&nbsp;
                    {
                      <GetLanguageString
                        props={"folder_document_inherit_permissions"}
                      />
                    }
                  </Label>
                </Col>
                <Col md="6">
                  <Label check for={"filePermissions"}>
                    <Input
                      type="radio"
                      id="filePermissions"
                      value={1}
                      name="filePermissions"
                      checked={filePermissions === 1}
                      onChange={handleFilePermissions}
                    />
                    &nbsp;&nbsp;
                    {
                      <GetLanguageString
                        props={"folder_document_apply_permissions"}
                      />
                    }
                  </Label>
                </Col>
              </Row>
              {filePermissions === 1 && (
                <>
                  <br></br>
                  <div className="ibox-content">
                    <Row>
                      <Col md="11"></Col>
                      <Col md="1" className="text-center">
                        <div>
                          <FaSquarePlus
                            color="green"
                            size={25}
                            style={{ cursor: "pointer" }}
                            onClick={addPermission}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {permissions?.map((item, index) => {
                        return (
                          <>
                            <div>
                              <br></br>
                              <Permissions
                                item={item}
                                index={index}
                                permissions={permissions}
                                setPermissions={setPermissions}
                              />
                              <br></br>
                            </div>
                            {permissions.length !== 1 && (
                              <Row>
                                <Col md="11"></Col>
                                <Col md="1" className="text-center">
                                  <div>
                                    <FaSquareMinus
                                      color="red"
                                      size={25}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => removeObject(index)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            )}
                            <div>
                              {index + 1 !== permissions.length && (
                                <div class="divider div-transparent div-dot"></div>
                              )}
                              <br></br>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </>
              )}
            </ModalBody>
          </Form>
          <ModalFooter>
            <Button
              color="default"
              onClick={() => {
                toggleModal();
                setUploadFolderModal(false);
              }}
            >
              {<GetLanguageString props="common_cancel" />}
            </Button>{" "}
            <Button
              color="primary"
              onClick={processFolderStructure}
              disabled={loading}
            >
              {<GetLanguageString props="document_upload_file" />}
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default UploadFolder;
