import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import moment from "moment";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import { ApiNotifications } from "./ApiNotifications";
import swal from "sweetalert";
import { convertUTCToLocalTime, isUrdu } from "../../constants/const";
import AddNewRafiqRequestSimilarRecords from "./AddNewRafiqRequestSimilarRecords";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

const SeeChanges = ({
  setModal,
  values,
  notificationName,
  documentNames,
  notificationId,
  serialNumber,
  isSerialNumberCancelled,
}) => {
  const toggle = () => setModal(false);
  const { t } = useTranslation();
  const [addRequestValues, setAddRequestValues] = useState([]);
  const [transferRequestValues, setTransferRequestValues] = useState();
  const [similarRecordsModal, setSimilarRecordsModal] = useState(false);
  const [similarRecords, setSimilarRecords] = useState();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [buttonDisable, setButtonDisable] = useState(
    Array(documentNames?.length).fill(false)
  );
  const [viewButtonDisable, setViewButtonDisable] = useState(
    Array(documentNames?.length).fill(false)
  );
  useEffect(() => {
    let pre = [];
    let post = [];
    if (notificationName.includes("move")) {
      setTransferRequestValues(values);
    } else {
      values.map(({ label, value }) => {
        return label.includes("Pre")
          ? pre.push({
              name: label.replace("Pre", ""),
              label: value,
            })
          : label.includes("Post")
          ? post.push({
              name: label.replace("Post", ""),
              label: value,
            })
          : post.push({
              name: label,
              label: value,
            });
      });

      const unitNameIndex = post.findIndex((item) => item.name === "UnitName");
      const referenceNoteIndex = post.findIndex(
        (item) => item.name === "ReferenceNote"
      );

      if (unitNameIndex !== -1 && referenceNoteIndex !== -1) {
        // Ensure referenceNoteIndex is greater than unitNameIndex (for moving above)
        if (referenceNoteIndex > unitNameIndex) {
          // Create a copy of the post array to avoid mutation
          const updatedPost = [...post];

          // Remove the "UnitName" item from its current position
          const unitNameItem = updatedPost.splice(unitNameIndex, 1)[0];

          // Insert the "UnitName" item at the desired position (before referenceNoteIndex)
          updatedPost.splice(referenceNoteIndex - 1, 0, unitNameItem);

          post = updatedPost;
        } else {
          console.warn("UnitName is already above ReferenceNote");
        }
      }
      setAddRequestValues(post);
    }
  }, [values, transferRequestValues, notificationName]);

  const openFile = (docType, index) => {
    setViewButtonDisable((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });
    ApiNotifications()
      .GetNotificationsDocs(notificationId, serialNumber, docType)
      .then((res) => {
        const binaryData = atob(res.data.bytes);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uintArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: res.data.fileType });
        const url = URL.createObjectURL(blob);

        const newTab = window.open();
        newTab.document.open();
        newTab.document.write(`
        <html>
        <head>
          <style>
            body {
              margin: 0;
              overflow: hidden;
            }
            iframe {
              width: 100%;
              height: 100%;
              border: none;
              background-color: transparent;
            }
          </style>
        </head>
        <body>
          <iframe src="${url}"></iframe>
        </body>
      </html>
    `);
        newTab.document.close();

        URL.revokeObjectURL(url);

        setViewButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
      })
      .catch((err) => {
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setViewButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        console.log(err.response.data);
      });
  };

  const downloadFile = (docType, index) => {
    setButtonDisable((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });
    ApiNotifications()
      .GetNotificationsDocs(notificationId, serialNumber, docType)
      .then((res) => {
        const binaryData = atob(res.data.bytes);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uintArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: res.data.fileType });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = res.data.fileName;
        link.click();

        URL.revokeObjectURL(url);

        URL.revokeObjectURL(url);
        setButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
      })
      .catch((err) => {
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        console.log(err.response.data);
      });
  };
  const handleSimilarRecords = () => {
    setSimilarRecords({
      cnic: addRequestValues?.find((item) => item.name === "Cnic")?.label || "",
      fullName:
        addRequestValues?.find((item) => item.name === "FullName")?.label || "",
      fatherName:
        addRequestValues?.find((item) => item.name === "FatherName")?.label ||
        "",
    });
    setSimilarRecordsModal(true);
  };

  const addSpaceToUpperCase = (string) => {
    return string.replace(/([A-Z])/g, " $1");
  };

  return (
    <div>
      {similarRecordsModal ? (
        <AddNewRafiqRequestSimilarRecords
          setSimilarRecordsModal={setSimilarRecordsModal}
          similarRecords={similarRecords}
        />
      ) : null}
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size="m"
          style={
            notificationName.includes("move_member")
              ? { maxWidth: "920px", width: "100%" }
              : { maxWidth: "720px", width: "100%" }
          }
          isOpen={true}
          className="inmodal"
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="common_changes" />}
          </ModalHeader>
          <ModalBody>
            {notificationName.includes("move_member") ? (
              <Row>
                <div className="ibox-content">
                  {transferRequestValues !== null &&
                  transferRequestValues !== undefined ? (
                    <div>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="notifications_see_changes_serial_number" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="m no-drag">
                            <>
                              {transferRequestValues.serialNumber}{" "}
                              {isSerialNumberCancelled && (
                                <span
                                  className={
                                    GetSelectedLanguage() === "ur" ? "ur" : ""
                                  }
                                >
                                  ({t("notifications_cancel")})
                                </span>
                              )}
                            </>
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="common_name" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m no-drag">
                            {transferRequestValues.fullName}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="member_fathers_name" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m no-drag">
                            {transferRequestValues.fatherName}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="notifications_see_changes_previous_address" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m no-drag">
                            {transferRequestValues.address !== ""
                              ? transferRequestValues.address
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="notifications_see_changes_new_address" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m no-drag">
                            {transferRequestValues.newAddress !== "" &&
                            transferRequestValues.newAddress != null
                              ? transferRequestValues.newAddress
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m">
                            {" "}
                            <GetLanguageString props="notifications_see_changes_contact" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left no-drag">
                          <h4 className=" m">
                            {transferRequestValues.contactNo}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m">
                            {" "}
                            <GetLanguageString props="member_email" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className=" m">
                            {transferRequestValues.email !== "" &&
                            transferRequestValues.email != null
                              ? transferRequestValues.email
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="notifications_see_changes_previous_unit_name" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m no-drag">
                            {transferRequestValues.previousUnit.slice(2)}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="notifications_see_changes_new_unit_name" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m no-drag">
                            {transferRequestValues.nextUnit.slice(2)}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m">
                            نصاب برائے مطالعہ / سماعت کی موجودہ کیفیت (مبتدی،
                            ملتزم، ذمہ داران، مدرسین)
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col
                          md="5"
                          className="text-left"
                          style={{ wordBreak: "break-word" }}
                        >
                          <h4
                            className={
                              isUrdu(transferRequestValues.nisabBaraEMutaliya)
                                ? "ur m no-drag"
                                : "m no-drag"
                            }
                          >
                            {transferRequestValues.nisabBaraEMutaliya !==
                              null &&
                            transferRequestValues.nisabBaraEMutaliya !== ""
                              ? transferRequestValues.nisabBaraEMutaliya
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m">
                            تعلق مع اللہ (عبادات، اخلاقیات، معاملات)
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col
                          md="5"
                          className="text-left"
                          style={{ wordBreak: "break-word" }}
                        >
                          <h4
                            className={
                              isUrdu(transferRequestValues.taluqMaAllah)
                                ? "ur m no-drag"
                                : "m no-drag"
                            }
                          >
                            {transferRequestValues.taluqMaAllah !== null &&
                            transferRequestValues.taluqMaAllah !== ""
                              ? transferRequestValues.taluqMaAllah
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m">
                            سمع و طاعت (تنظیمی اجتماعات میں حاضری کی کیفیت)
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col
                          md="5"
                          className="text-left"
                          style={{ wordBreak: "break-word" }}
                        >
                          <h4
                            className={
                              isUrdu(transferRequestValues.samaOTaat)
                                ? "ur m no-drag"
                                : "m no-drag"
                            }
                          >
                            {transferRequestValues.samaOTaat !== null &&
                            transferRequestValues.samaOTaat !== ""
                              ? transferRequestValues.samaOTaat
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m">
                            سمع و بصر (جرائد و دیگر کتب کا مطالعہ اور امیر محترم
                            کے خطبات جمعہ کا مطالعہ یا سماعت)
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col
                          md="5"
                          className="text-left"
                          style={{ wordBreak: "break-word" }}
                        >
                          <h4
                            className={
                              isUrdu(transferRequestValues.samaOBasar)
                                ? "ur m no-drag"
                                : "m no-drag"
                            }
                          >
                            {transferRequestValues.samaOBasar !== null &&
                            transferRequestValues.samaOBasar !== ""
                              ? transferRequestValues.samaOBasar
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m">
                            انفرادی دعوت / درس میں شرکت / تدریس کا اہتمام
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col
                          md="5"
                          className="text-left"
                          style={{ wordBreak: "break-word" }}
                        >
                          <h4
                            className={
                              isUrdu(transferRequestValues.infradiDawat)
                                ? "ur m no-drag"
                                : "m no-drag"
                            }
                          >
                            {transferRequestValues.infradiDawat !== null &&
                            transferRequestValues.infradiDawat !== ""
                              ? transferRequestValues.infradiDawat
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m">
                            جہاد بالمال خصوصا انفاق فی سبیل للہ کی کیفیت
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col
                          md="5"
                          className="text-left"
                          style={{ wordBreak: "break-word" }}
                        >
                          <h4
                            className={
                              isUrdu(transferRequestValues.jihadBilMaal)
                                ? "ur m no-drag"
                                : "m no-drag"
                            }
                          >
                            {transferRequestValues.jihadBilMaal !== null &&
                            transferRequestValues.jihadBilMaal !== ""
                              ? transferRequestValues.jihadBilMaal
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="5" className="text-left">
                          <h4 className="m ur">دیگر تفصیلات</h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m no-drag">
                            {transferRequestValues.otherInfo != null
                              ? transferRequestValues.otherInfo
                              : "-"}
                          </h4>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                </div>
              </Row>
            ) : notificationName.includes("move_habib") ? (
              <Row>
                <div className="ibox-content">
                  {transferRequestValues !== null &&
                  transferRequestValues !== undefined ? (
                    <div>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="settings_notification_type_name" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4
                            className={
                              isUrdu(transferRequestValues.fullName)
                                ? "ur m no-drag"
                                : "m no-drag"
                            }
                          >
                            {transferRequestValues.fullName}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="member_mobile_number" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="m no-drag">
                            {transferRequestValues.contactNo}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="member_email" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="m no-drag">
                            {transferRequestValues.email}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="member_address" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4
                            className={
                              isUrdu(transferRequestValues.address)
                                ? "ur m no-drag"
                                : "m no-drag"
                            }
                          >
                            {transferRequestValues.address !== ""
                              ? transferRequestValues.address
                              : "-"}
                          </h4>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            {" "}
                            <GetLanguageString props="member_member_detail_course_location" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className=" m no-drag">
                            {transferRequestValues.cityStateCountry}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="notifications_see_changes_previous_unit_name" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m no-drag">
                            {transferRequestValues.previousUnit.slice(2)}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="notifications_see_changes_new_unit_name" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="ur m no-drag">
                            {transferRequestValues.nextUnit.slice(2)}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="member_education" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="m no-drag">
                            {transferRequestValues.education}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="member_occupation" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4 className="m no-drag">
                            {transferRequestValues.occupation}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4" className="text-left">
                          <h4 className="m">
                            <GetLanguageString props="common_comments" />
                          </h4>
                        </Col>
                        <Col md="1">
                          <h4 className="m">{" : "}</h4>
                        </Col>
                        <Col md="5" className="text-left">
                          <h4
                            className={
                              isUrdu(transferRequestValues.comments)
                                ? "ur m no-drag"
                                : "m no-drag"
                            }
                          >
                            {transferRequestValues.comments}
                          </h4>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                </div>
              </Row>
            ) : notificationName.includes("add_new_rafiq") ? (
              <>
                <Row>
                  <div className="ibox-title">
                    <h5>
                      {<GetLanguageString props="member_basic_information" />}
                    </h5>
                  </div>
                  <div className="ibox-content">
                    {addRequestValues?.map((item, index) => {
                      return (
                        <div key={index}>
                          <Row>
                            <Col md="1"></Col>
                            <Col md="4">
                              {" "}
                              {item.name.includes("Id") ||
                              item.label === "" ? null : (
                                <h4 style={{ paddingLeft: 20 }}>
                                  {addSpaceToUpperCase(item.name)}{" "}
                                </h4>
                              )}
                            </Col>
                            <Col md="1">
                              {" "}
                              {item.name.includes("Id") || item.label === ""
                                ? null
                                : ":"}
                            </Col>
                            <Col md="4">
                              {item.name.includes("Id") ||
                              item.label === "" ? null : (
                                <h4
                                  className={
                                    item.name.includes("Name")
                                      ? "ur no-drag"
                                      : ""
                                  }
                                  style={
                                    item.name.includes("Cnic")
                                      ? { fontSize: "15px" }
                                      : { fontSize: "18px" }
                                  }
                                >
                                  {item.name.includes("Date") ||
                                  item.name.includes("DOB") ? (
                                    moment(
                                      convertUTCToLocalTime(item.label)
                                    ).format("DD/MM/yyyy")
                                  ) : item.name.includes("Cnic") ? (
                                    item.label.replace(
                                      /(\d{5})(\d{7})(\d{1})/,
                                      "$1-$2-$3"
                                    )
                                  ) : item.name.includes("Serial") ? (
                                    <>
                                      {item.label}{" "}
                                      {isSerialNumberCancelled && (
                                        <span
                                          className={
                                            GetSelectedLanguage() === "ur"
                                              ? "ur"
                                              : ""
                                          }
                                        >
                                          ({t("notifications_cancel")})
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    item.label
                                  )}
                                </h4>
                              )}
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <Button
                      color="success"
                      className={GetCustomStyles().btn_style}
                      onClick={handleSimilarRecords}
                    >
                      {
                        <GetLanguageString props="notifications_see_changes_see_similar_records" />
                      }
                    </Button>
                  </div>
                </Row>
                {documentNames?.length !== 0 ? (
                  <>
                    <br></br>
                    <Row>
                      <div className="ibox-title">
                        <h5>
                          {
                            <GetLanguageString props="member_add_member_documents" />
                          }
                        </h5>
                      </div>
                      <div className="ibox-content">
                        {documentNames?.map((item, index) => {
                          return (
                            <>
                              <Row key={index} className="m-2">
                                <Col md="6">
                                  <h4 className="no-drag">{item.fileName}</h4>
                                </Col>
                                <Col
                                  md="6"
                                  className={
                                    GetSelectedLanguage() === "en"
                                      ? "pull-right "
                                      : "pull-left"
                                  }
                                >
                                  <Button
                                    color="white"
                                    disabled={viewButtonDisable[index]}
                                    size="sm"
                                    onClick={() =>
                                      openFile(item.fileType, index)
                                    }
                                  >
                                    {viewButtonDisable[index] ? (
                                      <Spinner size="sm" color="dark" />
                                    ) : (
                                      <>
                                        <i class="fa fa-eye"></i>{" "}
                                        <GetLanguageString props="member_add_rafiq_request_view_document" />
                                      </>
                                    )}
                                  </Button>{" "}
                                  <Button
                                    color="white"
                                    size="sm"
                                    disabled={buttonDisable[index]}
                                    onClick={() =>
                                      downloadFile(item.fileType, index)
                                    }
                                  >
                                    {buttonDisable[index] ? (
                                      <Spinner size="sm" color="dark" />
                                    ) : (
                                      <>
                                        <i class="fa fa-download"></i>{" "}
                                        <GetLanguageString props="member_add_rafiq_request_download_document" />
                                      </>
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                      </div>
                    </Row>
                  </>
                ) : null}
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              {<GetLanguageString props="users_role_ok_btn" />}
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default SeeChanges;
