import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { GetLanguageString } from "../../helper/Components";
import { ApiUnit } from "../../unit/ApiUnit";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { ApiMudaris } from "./ApiMudaris";
import { GetSelectedLanguage } from "../../helper/Method";
import { ApiBooks } from "../../config/books/ApiBooks";

const SearchMudariseen = ({
  unitId,
  setUnit,
  setSearchMudariseenModel,
  searchMudariseenModel,
  setSearchButton,
  setUpdateCall,
  updateCall,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));

  // use state varieables declartion
  const [values, setValues] = useState(searchMudariseenModel);
  const [arabicTestStatuses, setArabicTestStatuses] = useState([]);
  const [selectedArabicTestStatus, setSelectedArabicTestStatus] = useState([]);
  const [tajweedTestStatuses, setTajweedTestStatuses] = useState([]);
  const [selectedTajweedTestStatus, setSelectedTajweedTestStatus] = useState([]);
  const [registrationStatuses, setRegistrationStatuses] = useState([]);
  const [selectedRegistrationStatuses, setSelectedRegistrationStatuses] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [searchBooks, setSearchBook] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(true);

  // unit type name
  const unitTypeName = "null";

  // use Effect Calls
  //  api calls to get the drop downs for the different drop down status
  useEffect(() => {
    ApiMudaris()
      .getAssessmentStatuses(1)
      .then((res) => {
        setArabicTestStatuses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    ApiMudaris()
      .getAssessmentStatuses(2)
      .then((res) => {
        setTajweedTestStatuses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    ApiMudaris()
      .GetRegistrationStatuses()
      .then((res) => {
        setRegistrationStatuses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

      // get books types
      ApiBooks()
      .getBooksForMudariseen()
      .then((res)=>{
        setSearchBook(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  // api call to get the units and sub units based on the selection
  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitTypeName, excludeExUnit)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setUnit({ unitId: record.value, unitName: record.label });
      setValues({
        ...values,
        unitId: record.value,
      });
    }
  };
  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    var v = value;

    if (name === "includeExUnit" && value === "true") v = true;
    else if (name === "includeExUnit" && value === "false") v = false;

    setValues({
      ...values,
      [name]: v,
    });
  };

  // function to handle the checkboxes for the books
  const handleCheckboxChange = (value) => {
    if (value === 'complete') {
      setSelectedCheckbox(true);
    } else if (value === 'incomplete') {
      setSelectedCheckbox(false);
    } else {
      setSelectedCheckbox(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.unitId === 0) values.unitId = unitId;

    values.arabicTestStatusIds = selectedArabicTestStatus
      ? selectedArabicTestStatus.map((record) => record.value)
      : [];
    values.tajweedTestStatusIds = selectedTajweedTestStatus
      ? selectedTajweedTestStatus.map((r) => r.value)
      : [];
    values.registrationStatusesIds = selectedRegistrationStatuses
      ? selectedRegistrationStatuses.map((r) => r.value)
      : [];
    values.booksIds = selectedBooks
    ? selectedBooks.map((r) => r.value)
    : [] ;

    values.booksStatus = selectedCheckbox;
    setSearchButton(true);
    setUpdateCall(!updateCall);
    setSearchMudariseenModel(values);
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* Row One filters */}
      <Row>
        <Col md="3">
          <FormGroup className="m-2">
            <Label>
              {<GetLanguageString props="member_search_by_regno" />}
            </Label>
            <Input
              type="text"
              name="regNo"
              value={values.regNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          {" "}
          <FormGroup>
            <Label className="col-form-label">
              {
                <GetLanguageString props="mudariseen_search_mudaris_by_arabic_test_status" />
              }
            </Label>
            <Select
              value={selectedArabicTestStatus}
              options={arabicTestStatuses}
              className="basic-single ur"
              classNamePrefix="Select"
              isClearable
              isMulti
              placeholder="کیفیت منتخب کریں"
              onChange={(record) => {
                setSelectedArabicTestStatus(record);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          {" "}
          <FormGroup>
            <Label className="col-form-label">
              {
                <GetLanguageString props="mudariseen_search_mudaris_by_tajweed_test_status" />
              }
            </Label>
            <Select
              value={selectedTajweedTestStatus}
              options={tajweedTestStatuses}
              className="basic-single ur"
              classNamePrefix="select"
              isClearable
              isMulti
              placeholder="کیفیت منتخب کریں"
              onChange={(record) => {
                setSelectedTajweedTestStatus(record);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {
                <GetLanguageString props="mudariseen_search_mudaris_by_registration_status" />
              }
            </Label>
            <Select
              value={selectedRegistrationStatuses}
              options={registrationStatuses.map((r) => ({
                value: r.value,
                label: <GetLanguageString props={r.label} />,
              }))}
              className="basic-single"
              classNamePrefix="select"
              isClearable
              isMulti
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              onChange={(record) => {
                setSelectedRegistrationStatuses(record);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {/* 2nd Row filters for Books drop down */}
      <Row>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {
                <GetLanguageString props="mudariseen_search_mudaris_by_nisab_drop_down" />
              }
            </Label>
            <Select
              value={selectedBooks}
              options={searchBooks}
              className="basic-single ur"
              classNamePrefix="select"
              isClearable
              isMulti
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              onChange={(record) => {
                setSelectedBooks(record);// Show checkboxes only if a value is selected
                if (!record) {
                  // If the dropdown is cleared, reset checkbox state
                  setSelectedCheckbox(null);
                }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {/* checkboxes if a nisab is selected */}
      {selectedBooks.length > 0 && (
        <Row>
            <Col md="2">
              {/* Checkbox for Complete */}
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    checked={selectedCheckbox === true}
                    onChange={() => handleCheckboxChange('complete')}
                  />{" "}
                  {<GetLanguageString props="courses_status_completed"/>}
                </Label>
              </FormGroup>
            </Col>

            <Col md="2">
              {/* Checkbox for Incomplete */}
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    checked={selectedCheckbox === false}
                    onChange={() => handleCheckboxChange('incomplete')}
                  />{" "}
                  {<GetLanguageString props="courses_status_incompleted"/>}
                </Label>
              </FormGroup>
            </Col>
        </Row>
        )}
      {/* Search by units and Subunits Row */}
      <Row>
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach((d) =>
            unitOptions.push({
              value: d.id,
              label: d.name,
            })
          );
          let selected = {};
          let unitName = "";
          if (record.parentUnitName !== null) unitName = record.parentUnitName;
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
            };
          }

          return (
            <Col md="2" key={index}>
              <FormGroup>
                <Label className="col-form-label">
                  {unitName !== "" ? (
                    <GetLanguageString
                      props="member_select_unit_name"
                      value={unitName}
                    />
                  ) : (
                    <GetLanguageString props="member_select_unit" />
                  )}
                </Label>
                <Select
                  options={unitOptions}
                  value={selected}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeUnits}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>
      {/* check box filters */}
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="excludeExUnit">
              <Input
                id="excludeExUnit"
                type="checkbox"
                name="excludeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup check>
            <Label check for="includeExUnit">
              <Input
                id="includeExUnit"
                type="checkbox"
                name="includeExUnit"
                checked={values.includeExUnit}
                value={!values.includeExUnit}
                onChange={handleInputChange}
              />
              {<GetLanguageString props="member_search_member_ex_rufqa" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button color="primary" className="m-l-sm" type="submit">
              {<GetLanguageString props="common_search" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchMudariseen;
