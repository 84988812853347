import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Row, Spinner, Table } from "reactstrap";
import swal from "sweetalert";
import {
  LoadingSpinner
} from "../../../constants/const";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { addMember } from "../ApiMember";
import AddNewDocument from "../Documents/AddNewDocument";
import EditDocument from "../Documents/EditDocument";

const UpdateDocuments = ({
  tab10,
  memberId,
  getDocuments,
  setDiscard,
  discard,
  activeTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [addDocumentModal, setAddDocumentModal] = useState(false);
  const [editDocumentModal, setEditDocumentModal] = useState(false);
  const [documents, setDocuments] = useState();
  const [documentId, setDocumentId] = useState();
  const [documentName, setDocumentName] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    if (getDocuments) {
      setLoading(true);
      addMember()
        .GeMemberDocsNames(memberId)
        .then((res) => {
          setDocuments(res.data);
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          console.log(err.response?.data || err);
        });
    }
  }, [getDocuments, refreshList, memberId]);

  useEffect(() => {
    // Check if the active tab has changed
    if (tab10 === "active") {
      if (activeTab && activeTab !== "tab10") {
        setDiscard(!discard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const [viewButtonDisable, setViewButtonDisable] = useState(
    Array(documents?.length).fill(false)
  );
  const [buttonDisable, setButtonDisable] = useState(
    Array(documents?.length).fill(false)
  );

  const handleDeleteDocument = (docId) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        addMember()
          .DeleteDocument(docId)
          .then((res) => {
            if (res.data === "Deleted") {
              swal({
                title: t("member_delete_document"),
                icon: "success",
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                buttons: "OK",
              });
              setLoading(false);
              setButtonDisable(false);
              setRefreshList(!refreshList);
            }
          })
          .catch((err) => {
            swal({
              title: "Error: " + err.response?.data || err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
            console.log(err.response?.data || err);
          });
      }
    });
  };
  const formatSize = (sizeInBytes) => {
    const units = ["bytes", "KB", "MB", "GB", "TB"];
    let size = sizeInBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
  };

  const downloadFile = (docType, index, pledgeId) => {
    setButtonDisable((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });
    addMember()
      .GetMemberDocs(memberId, docType, pledgeId)
      .then((res) => {
        const binaryData = atob(res.data.bytes);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uintArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: res.data.fileType });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = res.data.fileName;
        link.click();

        URL.revokeObjectURL(url);

        URL.revokeObjectURL(url);
        setButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
      })
      .catch((err) => {
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        console.log(err.response?.data || err);
      });
  };

  const openFile = (docType, index, pledgeId) => {
    setViewButtonDisable((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });
    addMember()
      .GetMemberDocs(memberId, docType, pledgeId)
      .then((res) => {
        const binaryData = atob(res.data.bytes);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uintArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: res.data.fileType });
        const url = URL.createObjectURL(blob);

        const newTab = window.open();
        newTab.document.open();
        newTab.document.write(`
        <html>
        <head>
          <style>
            body {
              margin: 0;
              overflow: hidden;
            }
            iframe {
              width: 100%;
              height: 100%;
              border: none;
              background-color: transparent;
            }
          </style>
        </head>
        <body>
          <iframe src="${url}"></iframe>
        </body>
      </html>
    `);
        newTab.document.close();

        URL.revokeObjectURL(url);

        setViewButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
      })
      .catch((err) => {
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setViewButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        console.log(err.response?.data || err);
      });
  };

  return (
    <div role="tabpanel" id="tab-10" className={"tab-pane " + tab10}>
      {addDocumentModal ? (
        <AddNewDocument
          setAddDocumentModal={setAddDocumentModal}
          memberId={memberId}
          setRefreshList={setRefreshList}
          refreshList={refreshList}
        />
      ) : null}
      {editDocumentModal ? (
        <EditDocument
          setEditDocumentModal={setEditDocumentModal}
          memberId={memberId}
          setRefreshList={setRefreshList}
          refreshList={refreshList}
          documentId={documentId}
          documentName={documentName}
        />
      ) : null}
      {!loading ? (
        <>
          <div className="panel-body">
            <Row>
              <div className={GetCustomStyles().btn_style}>
                <Button
                  color="primary"
                  size="sm"
                  className={GetCustomStyles().btn_style}
                  onClick={() => setAddDocumentModal(true)}
                >
                  <GetLanguageString props="member_member_detail_documents_add" />
                </Button>
              </div>
            </Row>
            {documents?.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_document_name" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_document_size" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="member_member_detail_document_upload_date" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="member_member_detail_pledges_name" />
                        }
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents?.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <GetLanguageString props={record.fileName} />
                          </td>
                          <td>{formatSize(record.fileSize)}</td>
                          <td className="text-center">
                            {record.uploadDate
                              ? moment(record.uploadDate)
                                  .format("DD/MM/yyyy") // Automatic UTC to local conversion
                              : "-"}
                          </td>
                          <td className="ur text-center">
                            {record.fileName !== "Kawaif Form"
                              ? record.pledge
                              : "-"}
                          </td>
                          <td className="text-end">
                            <Button
                              color="white"
                              disabled={viewButtonDisable[index]}
                              size="sm"
                              onClick={() =>
                                openFile(
                                  record.fileType,
                                  index,
                                  record.pledgeId
                                )
                              }
                            >
                              {viewButtonDisable[index] ? (
                                <Spinner size="sm" color="dark" />
                              ) : (
                                <>
                                  <i class="fa fa-eye"></i>{" "}
                                  <GetLanguageString props="member_add_rafiq_request_view_document" />
                                </>
                              )}
                            </Button>{" "}
                            <Button
                              color="white"
                              size="sm"
                              disabled={buttonDisable[index]}
                              onClick={() =>
                                downloadFile(
                                  record.fileType,
                                  index,
                                  record.pledgeId
                                )
                              }
                            >
                              {buttonDisable[index] ? (
                                <Spinner size="sm" color="dark" />
                              ) : (
                                <>
                                  <i class="fa fa-download"></i>{" "}
                                  <GetLanguageString props="member_add_rafiq_request_download_document" />
                                </>
                              )}
                            </Button>{" "}
                            <Button
                              color="white"
                              size="sm"
                              onClick={() => {
                                setEditDocumentModal(true);
                                setDocumentId(record.docId);
                                setDocumentName(record.fileName);
                              }}
                            >
                              <GetLanguageString props="common_edit" />
                            </Button>{" "}
                            <Button
                              color="white"
                              size="sm"
                              onClick={() => {
                                handleDeleteDocument(record.docId);
                              }}
                            >
                              <GetLanguageString props="common_delete" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              NoResult()
            )}
          </div>
        </>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default UpdateDocuments;
