import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Row, Table } from "reactstrap";
import swal from "sweetalert";
import {
  convertUTCToLocalTime,
  LoadingSpinner,
} from "../../../constants/const";
import { GetLanguageString, NoResult } from "../../helper/Components";
import {
  GetCustomStyles,
  GetPeriodName,
  GetSelectedLanguage,
} from "../../helper/Method";
import { addMember } from "../ApiMember";
import { useTranslation } from "react-i18next";

const UpdateLetterForCounselling = ({
  history,
  tab14,
  memberId,
  activeTab,
  setDiscard,
  discard,
  memberValues,
}) => {
  const [letterForCounsellingList, setLetterForCounsellingList] = useState([]);
  const [letterForCounselling, setLetterForCounselling] = useState({});
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [letterForCounsellingFrequency, setLetterForCounsellingFrequency] =
    useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tab14 === "active") {
      setLoading(true);
      addMember()
        .GetMemberLetterForCounselingData(memberId)
        .then((res) => {
          setLetterForCounsellingList(
            res.data.letterForCounsellingList
              ? res.data.letterForCounsellingList
              : []
          );
          setLetterForCounselling(res.data.letterForCounselling);
          setLetterForCounsellingFrequency(
            res.data.letterForCounsellingFrequency
          );
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  }, [memberId, tab14, refresh]);

  useEffect(() => {
    if (tab14 === "active") {
      if (activeTab && activeTab !== "tab14") {
        setDiscard(!discard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleDelete = (id) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        addMember()
          .DeleteReceivedLetterForCounselling(id)
          .then(() => {
            swal({
              title: t(
                "member_member_detail_letter_for_counselling_delete_success"
              ),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
            });
            setRefresh(!refresh);
            setLoading(false);
          })
          .catch((err) => {
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
          });
      }
    });
  };

  return (
    <div role="tabpanel" id="tab-14" className={"tab-pane " + tab14}>
      <div className="panel-body">
        {" "}
        <Row>
          {letterForCounselling != null ? (
            <div className={GetCustomStyles().btn_style}>
              <Button
                color="primary"
                size="sm"
                className={GetCustomStyles().btn_style}
                onClick={() =>
                  history.push({
                    pathname: `/member/updateMember/letterForCounselling/${memberId}`,
                    state: {
                      memberId: memberId,
                      letterForCounselling: letterForCounselling,
                      memberValues: memberValues,
                      letterFrequency: letterForCounsellingFrequency,
                    },
                  })
                }
              >
                <GetLanguageString props="settings_letter_for_counselling_add" />
              </Button>
            </div>
          ) : null}
        </Row>
        {!loading ? (
          letterForCounsellingList.length !== 0 ? (
            <div className="table-responsive">
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{<GetLanguageString props="member_reg_number" />}</th>
                    <th>
                      {
                        <GetLanguageString props="member_member_detail_letter_for_counselling_title" />
                      }
                    </th>
                    <th>
                      {
                        <GetLanguageString props="member_member_detail_responsibility_history" />
                      }
                    </th>
                    <th>
                      {
                        <GetLanguageString props="member_member_detail_unit_history" />
                      }
                    </th>
                    <th>
                      {
                        <GetLanguageString props="member_member_detail_letter_for_counselling_received_date" />
                      }
                    </th>
                    <th className="text-center">
                      {<GetLanguageString props="common_action" />}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {letterForCounsellingList.map((record, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{record.regNo}</td>
                        <td className="ur">
                          {record.title} (
                          {GetPeriodName(
                            record.letterFrequency,
                            record.letterForFrequency
                          )}{" "}
                          {record.year})
                        </td>
                        <td>
                          {record.responsibility.map((item, index) => {
                            return (
                              <Button
                                size="xs"
                                color="info"
                                key={index}
                                className="label label-info m-r-xs"
                              >
                                <h5 className="ur">{item}</h5>
                              </Button>
                            );
                          })}
                        </td>
                        <td>
                          <Button
                            size="xs"
                            color="info"
                            key={index}
                            className="label label-primary m-r-xs"
                          >
                            <h5 className="ur">{record.unitName}</h5>
                          </Button>
                        </td>
                        <td>
                          {record.receivedAt
                            ? moment(
                                convertUTCToLocalTime(record.receivedAt)
                              ).format("DD/MM/yyyy")
                            : null}
                        </td>
                        <td className="text-center">
                          <Button
                            color="white"
                            size="sm"
                            onClick={() => {
                              history.push({
                                pathname: `/member/updateMember/editLetterForCounselling/${memberId}`,
                                state: {
                                  memberId: memberId,
                                  letterForCounselling: record.title,
                                  receivedLetterForCounsellingId: record.id,
                                  memberValues: memberValues,
                                  letterFrequency: record.letterFrequency,
                                  letterForFrequency: record.letterForFrequency,
                                  letterYear: record.year,
                                  receivedAt: record.receivedAt,
                                },
                              });
                            }}
                          >
                            <GetLanguageString props="common_edit" />
                          </Button>{" "}
                          <Button
                            color="white"
                            size="sm"
                            onClick={() => handleDelete(record.id)}
                          >
                            <GetLanguageString props="common_delete" />
                          </Button>{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <NoResult />
          )
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default withRouter(UpdateLetterForCounselling);
