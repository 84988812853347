import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "reactstrap";
import swal from "sweetalert";
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { addMember } from "../ApiMember";

const MemberDocuments = ({ tab10, editValue }) => {
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documents, setDocuments] = useState();
  const [buttonDisable, setButtonDisable] = useState(
    Array(documents?.length).fill(false)
  );

  useEffect(() => {
    if (tab10 === "active") {
      if (editValue !== 0) {
        setDocumentLoading(true);
        addMember()
          .GeMemberDocsNames(editValue)
          .then((res) => {
            setDocuments(res.data);
            setDocumentLoading(false);
          })
          .catch((err) => {
            swal({
              title: "Error: " + err.response?.data || err,
              icon: "error",
              buttons: "OK",
            });
            setDocumentLoading(false);
            console.log(err.response?.data);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab10, editValue]);
  const [disabled, setDisabled] = useState(false);

  const [viewButtonDisable, setViewButtonDisable] = useState(
    Array(documents?.length).fill(false)
  );

  const formatSize = (sizeInBytes) => {
    const units = ["bytes", "KB", "MB", "GB", "TB"];
    let size = sizeInBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
  };

  const downloadFile = (docType, index, pledgeId) => {
    setButtonDisable((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });
    addMember()
      .GetMemberDocs(editValue, docType, pledgeId)
      .then((res) => {
        const binaryData = atob(res.data.bytes);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uintArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: res.data.fileType });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = res.data.fileName;
        link.click();

        URL.revokeObjectURL(url);

        URL.revokeObjectURL(url);
        setButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
      })
      .catch((err) => {
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        console.log(err.response?.data || err);
      });
  };
  const openFile = (docType, index, pledgeId) => {
    setDisabled(true);
    setViewButtonDisable((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });
    addMember()
      .GetMemberDocs(editValue, docType, pledgeId)
      .then((res) => {
        const binaryData = atob(res.data.bytes);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uintArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: res.data.fileType });
        const url = URL.createObjectURL(blob);

        const newTab = window.open();
        newTab.document.open();
        newTab.document.write(`
            <html>
            <head>
              <style>
                body {
                  margin: 0;
                  overflow: hidden;
                }
                iframe {
                  width: 100%;
                  height: 100%;
                  border: none;
                  background-color: transparent;
                }
              </style>
            </head>
            <body>
              <iframe src="${url}"></iframe>
            </body>
          </html>
        `);
        newTab.document.close();

        URL.revokeObjectURL(url);
        setDisabled(false);
        setViewButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
      })
      .catch((err) => {
        setDisabled(false);
        setViewButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        console.log(err.response?.data || err);
      });
  };
  return (
    <div role="tabpanel" id="tab-10" className={"tab-pane " + tab10}>
      <div className="panel-body">
        {!documentLoading ? (
          documents?.length !== 0 ? (
            <div className="table-responsive">
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      {
                        <GetLanguageString props="member_member_detail_document_name" />
                      }
                    </th>
                    <th>
                      {
                        <GetLanguageString props="member_member_detail_document_size" />
                      }
                    </th>
                    <th>
                      {
                        <GetLanguageString props="member_member_detail_document_upload_date" />
                      }
                    </th>
                    <th className="text-center">
                      {
                        <GetLanguageString props="member_member_detail_pledges_name" />
                      }
                    </th>
                    <th className="text-center">
                      {<GetLanguageString props="common_action" />}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {documents?.map((record, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <GetLanguageString props={record.fileName} />
                        </td>
                        <td>{formatSize(record.fileSize)}</td>
                        <td>
                          {record.uploadDate
                            ? moment(record.uploadDate).format("DD/MM/yyyy") // Automatic UTC to local conversion
                            : "-"}
                        </td>
                        <td className="ur text-center">
                          {record.fileName !== "Kawaif Form"
                            ? record.pledge
                            : "-"}
                        </td>
                        <td className="text-center">
                          <Button
                            color="white"
                            disabled={disabled}
                            size="sm"
                            onClick={() =>
                              openFile(record.fileType, index, record.pledgeId)
                            }
                          >
                            {viewButtonDisable[index] ? (
                              <Spinner size="sm" color="dark" />
                            ) : (
                              <>
                                <i class="fa fa-eye"></i>{" "}
                                <GetLanguageString props="member_add_rafiq_request_view_document" />
                              </>
                            )}
                          </Button>{" "}
                          <Button
                            color="white"
                            size="sm"
                            disabled={buttonDisable[index]}
                            onClick={() =>
                              downloadFile(
                                record.fileType,
                                index,
                                record.pledgeId
                              )
                            }
                          >
                            {buttonDisable[index] ? (
                              <Spinner size="sm" color="dark" />
                            ) : (
                              <>
                                <i class="fa fa-download"></i>{" "}
                                <GetLanguageString props="member_add_rafiq_request_download_document" />
                              </>
                            )}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            NoResult()
          )
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default MemberDocuments;
